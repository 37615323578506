import axios from "axios";
var APP_ENV = process.env.REACT_APP_ENV;
let REACT_APP_RUN_LOCAL = process.env.REACT_APP_RUN_LOCAL;
var url;

if (APP_ENV == "production") {
  url = "https://oncall.pega.com";
} else if (APP_ENV == "staging") {
  url =
    "http://a33e4051c02d340268ef7aa6de0742a9-1725813975.us-east-1.elb.amazonaws.com";
} else {
  url = "http://34.231.205.108";
}
if (REACT_APP_RUN_LOCAL == "true") url = "http://34.231.205.108";
const config = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

// const _encode = (response) => {
//   let buff = new Buffer.from(JSON.stringify(response));
//   return buff.toString("base64");
// };

const _decode = (response) => {
  let buff = new Buffer.from(response, "base64");
  return JSON.parse(buff.toString("ascii"));
};
export const getLoggedUser = () => {
  //UC-1.1.2
  return axios
    .get(`${url}/users/logged`, config)
    .then((res) => _decode(res.data))
    .catch((err) => {
      //UC-1.1.3
      if (err && err.response && err.response.status == 401) {
        window.location = "https://oncall.pega.com/login";
      } else {
        console.log(err);
      }
    });
};

export const getINCDetails = (incID) => {
  return fetch(`${url}/smecall/inc/PEGAORG-GCS-WORK ${incID}`);
};

export const getActiveSMEsAndTeams = (backlogId) => {
  return fetch(`${url}/smecall/getactivesmesandteams/${backlogId}`);
};

export const getOnCall = (incID, backlog) => {
  return fetch(`${url}/smecall/oncall/${incID}/${backlog}`);
};

export const postOnCall = (data) => {
  return axios.post(`${url}/smecall/oncall`, data, config);
};

export const getUserBacklogs = () => {
  return axios
    .get(`${url}/backlogs/userbacklogs`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getBacklogs = () => {
  // debugger;
  return axios
    .get(`${url}/backlogs`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getBacklog = (backlogId) => {
  return axios
    .get(`${url}/backlogs/${backlogId}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getTeamsInBacklog = (backlogId) => {
  return axios
    .get(`${url}/backlogs/${backlogId}/teams`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getPeopleInBacklog = (backlogId) => {
  return axios
    .get(`${url}/backlogs/${backlogId}/people`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getHolidays = (startDate, endDate) => {
  return axios
    .get(`${url}/holidays/${startDate}/${endDate}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getTeams = () => {
  return axios
    .get(`${url}/teams`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getTeam = (teamId) => {
  return axios
    .get(`${url}/teams/${teamId}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getAllUsers = () => {
  return axios 
  .get(`${url}/users/`, config)
  .then((res) => res.data)
  .catch((err) => console.log(err));
}

export const getUser = (userId) => {
  return axios
    .get(`${url}/users/${userId}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const updateUser = (userId, userData) => {
  return axios
    .put(`${url}/users/${userId}`, userData, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const postUser = (newUser) => {
  return axios
    .post(`${url}/users/create`, newUser, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const deleteUser = (userId) => {
  return axios
    .delete(`${url}/users/${userId}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getRotation = (backlogId, startDate) => {
  return axios
    .get(`${url}/rotations/${backlogId}/${startDate}`, config)
    .then((res) => {
      const resData = _decode(res.data);
      // const modifiedRotationsWithUniqueID = Object.entries(resData).map(
      //   ([key, obj]) => {
      //     return {
      //       [key]: Object.entries(obj).reduce((prev, curr) => {
      //         prev.push({
      //           [curr[1].ID]: curr[1],
      //         });
      //         return prev;
      //       }, []),
      //     };
      //   }
      // );
      return resData;
    })
    .catch((err) => console.log(err));
};

export const getRotations = (startDate, endDate) => {
  return axios
    .get(`${url}/rotations/date/${startDate}/${endDate}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getUserRotations = (userId, startDate, endDate) => {
  return axios
    .get(`${url}/rotations/user/${userId}/${startDate}/${endDate}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const postRotations = (rotationsData) => {
  return axios
    .post(`${url}/rotations`, rotationsData, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getActiveRotations = () => {
  return axios
    .get(`${url}/rotations/active`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getNextRotation = (arrayBacklogId) => {
  return axios
    .post(`${url}/rotations/next`, arrayBacklogId, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const deleteRotations = (rotationsData) => {
  return axios
    .delete(`${url}/rotations`, { data: { rotationsData } }, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getLocations = () => {
  return axios
    .get(`${url}/locations`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getReports = (limit) => {
  return axios
    .get(`${url}/reports/${limit}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getCompensationMonth = (month) => {
  return axios
    .get(`${url}/reports/compensation/month/${month}`, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const postCompensationMonth = (report) => {
  return axios
    .post(`${url}/reports/compensation`, report, config)
    .then((res) => _decode(res.data))
    .catch((err) => console.log(err));
};

export const getCompensationID = (id) => {
  return axios
    .get(`${url}/reports/compensation/id/${id}`, config)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const updateServiceTeamFlag = (teamId, isAServiceTeam) => {
  return axios.post(
    `${url}/teams/${teamId}/updateServiceTeam`,
    { isAServiceTeam },
    config
  );
};
