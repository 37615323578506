import React from "react";
import moment from "moment-timezone";
import { getLocalizedTimefromDate } from "../..//momentTools";

const MonthlyRotations = ({ month, rotations, timeZone }) => {

  const convertGMTtoUTC = (dateString) => {
    if(dateString) {
      const parsedDate = moment(dateString.replace(' GMT', ''), "YYYYMMDDTHHmmss.SSS");
      return getLocalizedTimefromDate(parsedDate.toISOString(), timeZone, "YYYY-MM-DD HH:mm")
    }
  };

  return (
    <div className="row bottom-20">
      <div className="col-sm-12 text-left">
        <h3 className="font-extra-bold text-dark-blue">{moment(month).format("MMMM YYYY")}</h3>
        {rotations &&
          Object.keys(rotations).map((key, index) => {
            let rotationBadge =
              rotations[key].Type === "Weekday" ? "teal" : rotations[key].Type === "Weekend" ? "yellow" : "orange";

            return (
              <div key={rotations[key].ID} className="rotation-item left-20 bottom-10">
                <span className="font-extra-bold">{moment(rotations[key].day).format("dddd")}</span>
                <span className={"badge text-white ml-2 bg-" + rotationBadge}>{rotations[key].Type}</span>
                <span className={"badge text-white ml-2 bg-dark-grey"}>{rotations[key].Class}</span>
                <span className={"badge text-white ml-2 bg-dark-grey"}>{rotations[key].Chunk}</span>
                <br />
                <div className="row justify-content-md-center">
                  <div className="col-5">
                    <span className="font-light">
                      Start: {convertGMTtoUTC(rotations[key].StartTime)}
                    </span>
                  </div>
                  <div className="col-5">
                    <span className="font-light">
                      End: {convertGMTtoUTC(rotations[key].EndTime)}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        {!rotations && <p className="left-20">No rotations</p>}
      </div>
    </div>
  );
};

export default MonthlyRotations;