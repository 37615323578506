import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { getLoggedUser } from "../api";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    //UC-1.4.3 OnClick toggle expand-collapse
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    const { ActiveUser, Roles } = this.props;

    const collapsed = this.state.collapsed;
    const classOne = collapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: "#1F2555" }}>
          <span className="navbar-brand mb-0 h1">Engineering On-Call</span>
          {/* UC-1.4.2 Toggle Burger Button in Responsive mode */}
          <button
            onClick={this.toggleNavbar}
            className={`${classTwo}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`${classOne}`} id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {(Roles.BacklogRepresentative.includes(ActiveUser.role) ||
                Roles.OnCallEngineer.includes(ActiveUser.role)) && (
                <li className="nav-item">
                  {/* UC-1.4.3 Show link to Backlog for Roster Manager and Engineer on Roster*/}
                  <NavLink to="/backlog" className="nav-link">
                    My Backlogs
                  </NavLink>
                </li>
              )}
              {/* UC-1.4.4 Show link to coverage for all*/}
              <li className="nav-item">
                <NavLink to="/coverage" className="nav-link">
                  Coverage
                </NavLink>
              </li>
              {/* UC-1.4.5 Show link to faq */}
              <li className="nav-item">
                <NavLink to="/faq" className="nav-link">
                  FAQ
                </NavLink>
              </li>
            </ul>

            <ul className="navbar-nav ml-auto">
              {/* UC-1.4.6 Show link to call SME page only if user is Escalation Manager */}
              {Roles.EscalationLeadership.includes(ActiveUser.role) && (
                <li className="nav-item">
                  <NavLink to="/callSME" className="nav-link">
                    &#128222; Call SME
                  </NavLink>
                </li>
              )}
              {/* UC-1.4.7 Show link to Create Rotation page only if user is Admin BacklogRep  */}
              {/* {Roles.Administrator.includes(ActiveUser.role) && <li className="nav-item">
                <NavLink to='/rotation' className="nav-link" >Rotation</NavLink>
              </li>} */}
              {/* UC-1.4.7 Show link to Reports page only if user is Admin  */}
              {Roles.Administrator.includes(ActiveUser.role) && (
                <li className="nav-item">
                  <NavLink to="/reports" className="nav-link">
                    Reports
                  </NavLink>
                </li>
              )}
              {!ActiveUser.id && (
                <li className="nav-item">
                  {/* UC-1.4.8 Show Login when user is not logged in */}
                  <a href="https://oncall.pega.com/login" className="nav-link">
                    Log In
                  </a>
                </li>
              )}
              {ActiveUser.id && (
                <li className="nav-item">
                  <NavLink to={"/profile/" + ActiveUser.id}>
                    <span className="text-white nav-link">
                      <span className="text-decoration-none ml-.5">{ActiveUser.displayName}</span>
                      <i className="material-icons">account_circle</i>
                    </span>
                  </NavLink>
                </li>
              )}
              {ActiveUser.id && (
                <li className="nav-item">
                  {/* UC-1.4.11 Show Logout link when user is logged in */}
                  <a href="https://oncall.pega.com/logout" className="nav-link">
                    Log Out
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navbar;