import React, { useState, useEffect } from "react";
import MyModal from "../Modal";
import { Timezones } from "../Timezones";
import { updateUser, getLocations, getBacklogs } from "../../api.js";

const EditProfile = (props) => {
  const { editData, user, toggleLoader, onSuccessEdit, onFailureEdit, dissmissModal, labels } = props;
  const valueData = user[editData];

  const [editedValue, setEditedValue] = useState(null);
  const [selectValues, setSelectValues] = useState([]);
  const [disableButtons, setDisableButtons] = useState(true);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [extension, setExtension] = useState("");
  const [recentUpdate, setRecentUpdate] = useState("U");

  useEffect(() => {
    if (editData === "Location") {
      getLocations().then((result) => {
        const locations = {};
        Object.keys(result).forEach((key) => {
          locations[result[key].id] = result[key];
        });
        setSelectValues(locations);
      });
    }

    if (editData === "BacklogID") {
      getBacklogs().then((result) => {
        const backlogs = {};
        Object.keys(result).forEach((key) => {
          backlogs[result[key].id] = result[key];
        });
        setSelectValues(backlogs);
      });
    }

    if ((editData === "PhonePrimary" || editData === "PhoneFallback") && valueData) {
      const parts = valueData.split("-");
      if (valueData.endsWith("U")) {
        setCountryCode(parts[0]);
        setPhoneNumber(parts[1]);
        setExtension(parts[2]);
        setRecentUpdate("U");
      } else {
        setPhoneNumber(parts[0]);
      }
    }

    if (editData === "Timezone") {
      setSelectValues(Timezones);
    }
  }, [editData, valueData]);

  useEffect(() => {
    if (editData === "PhonePrimary" || editData === "PhoneFallback") {
      const newFullPhone = `${countryCode}-${phoneNumber}-${extension}-${recentUpdate}`;
      setEditedValue({
        name: editData,
        value: newFullPhone,
      });
    }
  }, [countryCode, phoneNumber, extension]);

  const validatePhoneNumber = (event, handlerType) => {
    event.preventDefault();
    const inputValue = event.target.value;  
    if (handlerType === 'ext' && inputValue === "") {
      setDisableButtons(false);
    } else {
      let regex = new RegExp("^[1-9][0-9]*$");
      if (inputValue === "" || !regex.exec(inputValue)) {
        setDisableButtons(true);
      } else {
        setDisableButtons(false);
      }
    }

    switch (handlerType) {
      case "country":
        setCountryCode(inputValue);
        setRecentUpdate("U");
        break;

      case "phone":
        setPhoneNumber(inputValue);
        setRecentUpdate("U");
        break;

      case "ext":
        setExtension(inputValue);
        setRecentUpdate("U");
        break;

      default:
        break;
    }
  };

  const handleDataChange = (handler) => {
    setEditedValue({
      name: handler.target.name,
      value: handler.target.value,
    });
    setDisableButtons(false);
  };

  const onEditData = () => {
    toggleLoader(true);
    updateUser(props.user.ID, editedValue)
      .then((res) => {
        onSuccessEdit(res.message);
        dissmissModal();
        toggleLoader(false);
      })
      .catch((res) => {
        console.log(res);
        onFailureEdit(res.message);
        dissmissModal();
        toggleLoader(false);
      });
  };

  const onCancel = () => {
    dissmissModal();
  };

  const renderInput = (editData) => {
    switch (editData) {
      case "PhonePrimary":
      case "PhoneFallback":
        return (
          <div className="row">
            <div className="form-group col-md-4">
              <label>Country Code</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">+</span>
                </div>
                <input
                  type="text"
                  name="country"
                  className="form-control"
                  required
                  value={countryCode || ""}
                  onChange={(e) => validatePhoneNumber(e, "country", editData)}
                />
              </div>
            </div>
            <div className="form-group col-md-5">
              <label>Phone Number</label>
              <input
                type="text"
                name="phone"
                required
                className="form-control"
                value={phoneNumber || ""}
                onChange={(e) => validatePhoneNumber(e, "phone", editData)}
              />
            </div>
            <div className="form-group col-md-3">
              <label>Extension</label>
              <input
                type="text"
                name="extension"
                className="form-control"
                value={extension || ""}
                onChange={(e) => validatePhoneNumber(e, "ext", editData)}
              />
            </div>
          </div>
        );

      case "Timezone":
        return (
          <select className="form-control" name={editData} value={valueData} onChange={handleDataChange}>
            {Object.keys(selectValues).map((key) => (
              <option key={selectValues[key].id} value={selectValues[key].id}>
                {selectValues[key].name} ({selectValues[key].id})
              </option>
            ))}
          </select>
        );
      case "Location":
      case "BacklogID":
      default:
        return (
          <input
            type="text"
            className="form-control"
            name={editData}
            defaultValue={valueData}
            onChange={handleDataChange}
          />
        );
    }
  };

  return (
    <MyModal
      visible={true}
      modalBackdropClicked={dissmissModal}
      header={
        <div>
          {"Edit"} {labels[editData]}
          <span className="rt-modal-close">
            <button type="button" className="btn btn-default" onClick={dissmissModal}>
              <i className="material-icons">close</i>
            </button>
          </span>
        </div>
      }
      body={
        <div className="EditProfile">
          <div className="col-sm-12">
            <div className="row">
              <div className="mx-auto text-left" style={{ minWidth: "100%" }}>
                <div className="form-group">{renderInput(editData)}</div>
              </div>
            </div>
          </div>
        </div>
      }
      btnSecondary={{
        text: "Cancel",
        color: "btn-danger",
        onClick: onCancel,
      }}
      btnPrimary={{
        text: "Save",
        color: "btn-primary",
        disableButtons,
        onClick: onEditData,
      }}
    />
  );
};

export default EditProfile;